import * as THREE from 'three'
import Experience from './Experience';

export default class RayCaster 
{
    constructor()
    {
        this.experience = new Experience();
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        
       
        this.mouse = new THREE.Vector2();

        window.addEventListener('mousemove', (_event) => 
        {
            this.mouse.x = _event.clientX / this.sizes.width * 2 - 1;
            this.mouse.y = - (_event.clientY / this.sizes.height) * 2 + 1;

            //console.log(this.mouse)
        })

        this.setInstance();
    }

    setInstance() 
    {
        this.instance = new THREE.Raycaster();
        this.instance.setFromCamera(this.mouse, this.camera.instance);
    }

    update()
    {
        this.instance.setFromCamera(this.mouse, this.camera.instance);
    }
}