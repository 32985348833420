import EventEmitter from './EventEmitter.js'

export default class Sizes extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.width = window.innerWidth
        this.height = window.innerHeight
        this.pixelRatio = Math.min(window.devicePixelRatio, 2)
        this.scrollY = window.scrollY;
        this.scrollValue = this.scrollY / this.height;
        // Resize event
        window.addEventListener('resize', () =>
        {
            this.width = window.innerWidth
            this.height = window.innerHeight
            this.pixelRatio = Math.min(window.devicePixelRatio, 2)
            this.trigger('resize')
        })

        window.addEventListener('scroll', () => {
            this.scrollY = window.scrollY
            this.scrollValue = this.scrollY / this.height;
            this.trigger('scroll')
            
        
        })
        
    }
}