import * as THREE from "three";
import Experience from "../Experience.js";
import { gsap } from "gsap";

export default class Room {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.group = this.experience.group;
    this.sizes = this.experience.sizes;
    this.raycaster = this.experience.raycaster;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    this.currentIntersect = [];
    this.resource = this.resources.items.roomModel;
    this.textures = this.resources.items.screenTexture;
    this.texturesHover = this.resources.items.screenTextureHover;
    this.cat = this.experience.world.cat;
    
    this.peachToggle = this.resources.items.peachToggle;
    this.socksToggle = this.resources.items.socksToggle;
    this.peachToggle.flipY = false;
    this.socksToggle.flipY = false;
    this.peachToggle.colorSpace = THREE.SRGBColorSpace;
    this.socksToggle.colorSpace = THREE.SRGBColorSpace;

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("room");
    }

    window.addEventListener("click", () => {
      if (this.currentIntersect && !this.isAnyDivVisible) {
        switch (this.currentIntersect.object) {
          case this.picture1:
            const div = document.getElementById("picture1-div");
            if (div) {
              this.showDiv(div);
            }
            break;
          case this.picture2:
            const div2 = document.getElementById("picture2-div");
            if (div2) {
              this.showDiv(div2) 
            }
            break;
          case this.picture3:
            const div3 = document.getElementById("picture3-div");
            if (div3) {
              this.showDiv(div3) 
            }
            break;
          case this.sheets:
            const div4 = document.getElementById("contact-div");
            if (div4) {
              this.showDiv(div4) 
            }
            break;
          case this.leftScreen:
            const div5 = document.getElementById("project-div");
            if (div5) {
              this.showDiv(div5) 
            }
            break;
          case this.catToggle:
            this.cat.updateTexture();
            if (this.catToggle.position.z === 0) {
              this.catToggle.material.map = this.socksToggle
              gsap.to(this.catToggle.position, {
                z: -0.65,
                duration: 0.3,
                ease: "power1.inOut",
              });
            } else {
              this.catToggle.material.map = this.peachToggle
              gsap.to(this.catToggle.position, {
                z: 0,
                duration: 0.3,
                ease: "power1.inOut",
              });
            }
            break;
        }
      }
    });

    this.setModel();
  }

  setModel() {
    this.model = this.resource.scene;

    this.group.addModel(this.model);

    this.lampMaterial = new THREE.MeshStandardMaterial({
      color: 0xFFCC99,
      transparent: true, 
      opacity: 0.95,
      emissive: 0xFFAA33,        
      emissiveIntensity: 10, 
    })
    this.screenMaterial = new THREE.MeshStandardMaterial();
    this.screenMaterial.map = this.textures;
    this.screenMaterial.emissive = new THREE.Color(0xffe9d4);
    this.screenMaterial.emissiveMap = this.textures;
    this.screenMaterial.emissiveIntensity = 0;
    this.screenMaterial.color = new THREE.Color(0x000000);

    this.textures.flipY = false;
    this.textures.colorSpace = THREE.SRGBColorSpace;
    this.texturesHover.flipY = false;
    this.texturesHover.colorSpace = THREE.SRGBColorSpace;
    this.model.traverse((child) => {
      if (
        child.name === "Shelf_1" ||
        child.name === "Keyboard_1" ||
        child.name === "Desktop_1" ||
        child.name === "Floor_1"
      ) {
        child.receiveShadow = true;
      } else if (child.name === "HourHand_1") {
        this.hourHand = child;
      } else if (child.name === "MinuteHand_1") {
        this.minuteHand = child;
      } else if (child.name === "Picture1_1") {
        this.picture1 = child;
      } else if (child.name === "Picture2_1") {
        this.picture2 = child;
      } else if (child.name === "Picture3_1") {
        this.picture3 = child;
      } else if (child.name === "Envelopes_1") {
        this.envelope = child;
      } else if (child.name === "Sheets_1") {
        this.sheets = child;
      } else if (child.name === "LeftScreen_1") {
        child.material = this.screenMaterial;
        this.leftScreen = child;
      } else if (child.name === "RightScreen_1") {
        child.material = this.screenMaterial;
        this.rightScreen = child;
      } else if (child.name === "CatToggle_1") {
        this.catToggle = child;
      } else if (child.name === "LampShade_1") {
        this.lampShade = child;
        this.lampShade.material = this.lampMaterial;
      }
    });

    if (this.debug.active) {
      this.debugFolder
        .add(this.envelope.position, "x")
        .name("envelopeX")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.envelope.position, "y")
        .name("envelopeY")
        .min(-5)
        .max(5)
        .step(0.001);

      this.debugFolder
        .add(this.envelope.position, "z")
        .name("envelopeZ")
        .min(-5)
        .max(5)
        .step(0.001);
    }
    console.log(document.getElementById("picture1-div").style.display);
  }

  showDiv(div) 
  {
    if (div) {
        div.style.display = 'flex'; // Set display to flex to make it visible
        setTimeout(() => {
            div.style.opacity = '1'; // Start the pop-in effect by setting opacity
        }, 10); // Slight delay to ensure display change is registered
    }
  }

  update() {
    const date = new Date();
    const minute = date.getMinutes();
    const hour = date.getHours() % 12; // Convert 24-hour format to 12-hour format
    const minuteRadians = (Math.PI * 2) / 60;
    const hourRadians = (Math.PI * 2) / 12;

    // Minute hand rotation
    this.minuteHand.rotation.x = -minute * minuteRadians;

    // Hour hand rotation (include the effect of minutes on the hour hand)
    this.hourHand.rotation.x = -(
      hour * hourRadians +
      (minute / 60) * hourRadians
    );

    const divs = document.getElementsByClassName("global-div");
    this.isAnyDivVisible = false;

    for (const div of divs) {
      if (div.style.display === "flex") {
        this.isAnyDivVisible = true; // Set the flag if any global-div is visible
        document.body.style.cursor = "default";
        break;
      }
    }
    
      this.objectsToTest = [
        this.picture1,
        this.picture2,
        this.picture3,
        this.sheets,
        this.leftScreen,
        this.catToggle,
      ];
      this.intersects = this.raycaster.instance.intersectObjects(
        this.objectsToTest
      );
      if (!this.isAnyDivVisible) {
      for (const object of this.objectsToTest) {
        document.body.style.cursor = "default";
        if (object.name === "Sheets_1") {
          gsap.to(this.envelope.position, {
            y: 0,
            duration: 0.2,
            ease: "power1.inOut",
          });
          gsap.to(this.envelope.scale, {
            x: 1,
            y: 1,
            z: 1,
            duration: 0.1,
            ease: "power1.inOut",
          });
        } else if (object.name === "LeftScreen_1") {
          this.screenMaterial.map = this.textures;
        } else {
          gsap.to(object.scale, {
            x: 1,
            y: 1,
            z: 1,
            duration: 0.5,
          });
        }
      }

      for (const intersect of this.intersects) {
        document.body.style.cursor = "pointer";
        if (intersect.object.name === "Sheets_1") {
          gsap.to(this.envelope.position, {
            y: 0.2,
            duration: 0.1,
            ease: "power1.inOut",
          });
          gsap.to(this.envelope.scale, {
            x: 1.1,
            y: 1.1,
            z: 1.1,
            duration: 0.1,
            ease: "power1.inOut",
          });
        } else if (intersect.object.name === "LeftScreen_1") {
          this.screenMaterial.map = this.texturesHover;
        } else {
          gsap.to(intersect.object.scale, {
            x: 1.03,
            y: 1.03,
            z: 1.03,
            duration: 0.5,
          });
        }
      }
    }
      if (this.intersects.length) {
        this.currentIntersect = this.intersects[0];
      } else {
        this.currentIntersect = null;
      }
    }
}
