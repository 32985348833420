export default [
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
        [
            'textures/environmentMap/px.jpg',
            'textures/environmentMap/nx.jpg',
            'textures/environmentMap/py.jpg',
            'textures/environmentMap/ny.jpg',
            'textures/environmentMap/pz.jpg',
            'textures/environmentMap/nz.jpg'
        ]
    },
    {
        name: 'catModel',
        type: 'gltfModel',
        path: 'models/cat_v4.glb'
    },
    {
        name: 'roomModel',
        type: 'gltfModel',
        path: 'models/scenetextured.glb'
    },
    {
        name:'screenTexture',
        type: 'texture',
        path: 'textures/screen.jpg'
    },
    {
        name:'screenTextureHover',
        type: 'texture',
        path: 'textures/screen_hover.jpg'
    },
    {
        name: 'socksBaseColor',
        type: 'texture',
        path: 'textures/Socks_baseColor.png',

    },
    {
        name: 'peachBaseColor',
        type: 'texture',
        path: 'textures/Cat_baseColor.png',

    },
    {
        name: 'peachORM',
        type: 'texture',
        path: 'textures/Cat_occlusionRoughnessMetallic.png',

    },
    {
        name: 'socksToggle',
        type: 'texture',
        path: 'textures/PicturesSocks_baseColor.png',

    },
    {
        name: 'peachToggle',
        type: 'texture',
        path: 'textures/Pictures_baseColor.png',

    },

]